import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RandomArticle from '../events/RandomArticle';
import Contacts from './Contacts';

const About = () => (
  <Fragment>
    <Helmet>
      <title>SmartArt - Про нас</title>
      <meta
        name='description'
        content='Центр розвитку дитини Smart Art – територія, де досвідчені педагоги
        розкривають таланти та плекають зернини творчості та
        індивідуальності кожної дитини.'
      />
    </Helmet>

    <section className='about main-section'>
      <div className='section-header'>
        <h1 className='hidden'>SmartArt</h1>
        <h2>Про нас</h2>
        <div className='stripe'></div>
      </div>

      <div className='about-body'>
        <svg height='72' width='72'>
          <circle cx='36' cy='36' r='36' fill='#faa635' />
        </svg>
        <svg height='120' width='120'>
          <circle cx='60' cy='60' r='60' fill='#fff' />
        </svg>
        <svg height='170' width='170'>
          <circle cx='85' cy='85' r='85' fill='#faa635' />
        </svg>
        <svg height='130' width='130'>
          <circle cx='65' cy='65' r='65' fill='#fff' />
        </svg>

        <p>
          Центр розвитку дитини Smart Art – територія, де досвідчені педагоги
          розкривають таланти та плекають зернини творчості та індивідуальності
          кожної дитини. <br />
          *<br />
          Пропонуємо різноманітні корисні та пізнавальні заняття, які допоможуть
          Вашим дітям відкривати всю красу та багатогранність світу довкола.
          Проводимо інтегровані заняття, що включають різні види діяльності,
          підготовку до школи, навички спілкування, творчої уяви, художньої
          діяльності, ритміку та комплексні заняття за програмами
          інтелектуального розвитку.
          <br />
          *<br />
          В своїй роботі використовуємо актуальні методики провідних педагогів
          Монтессорі, Домана, Л.Зайцевої, Л.Шелестової.
          <br />
          *<br />
          Працюємо повну годину, змінюючи види діяльності, враховуючи
          індивідуальні особливості дитини!
        </p>
      </div>
    </section>

    <section className='services-preview'>
      <div className='section-header'>
        <h3>Ми пропонуємо</h3>
        <div className='stripe'></div>
      </div>

      <div className='service-block'>
        <span>Міні-сад (з 2 років)</span>
        <div className='service-body'>
          <div className='service-body-inner'>
            <b>
              Це місце, де у любові, грі, музиці та інших цікавих заняттях Ваші
              діти проведуть час, поки Вас немає поруч, дізнаються, що таке
              дружба та отримають життєво необхідні вміння і навички, відчують
              себе маленькими дослідниками{' '}
            </b>
            <br />
            <i>
              <ul>
                На Ваших малюків чекає:
                <li>- Ранкова гімнастика;</li>
                <li>- Світ навколо тебе та уроки обережності;</li>
                <li>- Казкотерапія;</li>
                <li>- Рухливі, сюжетно-рольові та дидактичні ігри ;</li>
                <li>
                  - Розвиваючі заняття (розвиток мовлення, математика, логіка,
                  навчання читанню, елементи грамоти, підготовка до письма);
                </li>
                <li>- Соціальна адаптація;</li>
                <li>- Доброзичлива атмосфера та уважне ставлення.</li>
              </ul>
            </i>
            <b>Розклад роботи:</b>
            <i> з понеділка по пятницю з 9:00 до 13:00; 15:00 до 19:00.</i>
          </div>
          <div className='service-body-img'>
            <img src='img/services/Smart-72.jpg' alt='Міні-сад' />
          </div>
        </div>
      </div>

      <div className='service-block body-reversed'>
        <span>Підготовка до школи</span>
        <div className='service-body'>
          <div className='service-body-inner'>
            <b>
              Види занять: «За два кроки до школи» (з 4 років) «За крок до
              школи» (з 5 років) Інтелектуальна готовність дитини є пріоритетною
              для успішного навчання в школі, успішної взаємодії з однолітками і
              навколишнім світом.{' '}
            </b>
            <br />
            <i>
              <ul>
                Курс занять передбачає:
                <li>- формування логіко-математичних навичок;</li>
                <li>- підготовку руки до письма;</li>
                <li>- основи читання;</li>
                <li>- розвиток мовлення;</li>
                <li>- навчання елементів грамоти;</li>
                <li>- формування знань про навколишній світ;</li>
                <li>- логіка</li>
              </ul>
            </i>
            <b>На Вас чекаємо:</b>
            <i>
              понеділок, вівторок, середа, четвер з 09:00 до 10:00 вівторок,
              четвер з 16:00 до 17:00
            </i>
          </div>
          <div className='service-body-img'>
            <img src='img/services/pidg_school.jpg' alt='Підготовка до школи' />
          </div>
        </div>
      </div>

      <div className='service-block'>
        <span>Психологічна готовність до школи</span>
        <div className='service-body'>
          <div className='service-body-inner'>
            <b>
              Психологічна готовність до школи передбачає такий рівень
              психічного розвитку дитини, який створює умови для успішного
              опанування навчальної діяльності, позитивного ставлення до вступу
              в школу, сформованості «внутрішньої позиції школяра»{' '}
            </b>
            <br />
            <i>
              <ul>
                Курс занять передбачає:
                <li>- формування позитивної навчальної шкільної мотивації;</li>
                <li>
                  - розвиток психічних процесів (мислення, пам’яті, сприйняття,
                  уваги, уяви);
                </li>
                <li>- розвиток дрібної моторики;</li>
                <li>- сприяння розвитку самостійності дітей;</li>
                <li>- формування довільної поведінки;</li>
                <li>
                  - розвиток навиків спілкування і взаємодії з однолітками та
                  дорослими
                </li>
              </ul>
            </i>
            <b>На Вас чекаємо</b>
            <i>щоп’ятниці з 09:00 до 10:00, з 16:00 до 17:00</i>
          </div>
          <div className='service-body-img'>
            <img src='img/services/Smart-102.jpg' alt='Міні-сад' />
          </div>
        </div>
      </div>

      <div className='centered-btn'>
        <Link to='/services' className='rounded-btn orange-btn'>
          Детальніше
        </Link>
      </div>
    </section>

    <section className='team'>
      <div className='section-header'>
        <h3>Наш коллектив</h3>
        <div className='stripe'></div>
      </div>

      <div className='team-img'>
        <img src='img/educators/Smart-194.jpg' alt='Наш коллектив' />
      </div>

      <div className='educators'>
        <div className='educator'>
          <img
            src='img/educators/educator_ne.jpg'
            width='165'
            height='165'
            alt='Наталія Євгеніївна Логопед'
          />
          <div>
            <span>Наталія Євгеніївна</span>
            <b>Логопед</b>
            <p>
              <b>Освіта:</b> <i>вища</i>
            </p>
            <p>
              <b>Стаж роботи:</b> <i>28 років</i>
            </p>
            <i>
              Заняття з розвитку мовлення та корекції допоможуть дитині
              правильно формулювати фрази, речення, розповіді. Індивідуальні
              консультації для батьків
            </i>
          </div>
        </div>

        <div className='educator'>
          <img
            src='img/educators/educator_li.jpg'
            width='165'
            height='165'
            alt='Лариса Іванівна Студія Творчості'
          />
          <div>
            <span>Лариса Іванівна</span>
            <b>Викладач</b>
            <i>
              Творча майстерня, Творча майстерня, «Паперові фантазії», Художня
              студія, Ранній розвиток, «Я самостійний»
            </i>
            <p>
              <b>Освіта:</b> <i>вища</i>
            </p>
            <p>
              <b>Стаж роботи:</b> <i>28 років</i>
            </p>
            <i>
              Допоможе розвинути творчі здібності, уяву та креативність, розкриє
              потенціал Вашої дитини.
            </i>
          </div>
        </div>

        <div className='educator'>
          <img
            src='img/educators/educator_sg.jpg'
            width='165'
            height='165'
            alt='Світлана Григорівна Вокал'
          />
          <div>
            <span>Світлана Григорівна</span>
            <b>Викладач</b>
            <i>Вокал, Ритміка</i>
            <p>
              <b>Освіта:</b> <i>вища</i>
            </p>
            <p>
              <b>Стаж роботи:</b> <i>30 років</i>
            </p>
            <i>
              Уроки вокалу — це прекрасний спосіб для дітей покращити свій
              настрій, відчути радість, щастя, стати більш емоційними і
              відкритими.
            </i>
          </div>
        </div>

        <div className='educator'>
          <img
            src='img/educators/educator_ym.jpg'
            width='165'
            height='165'
            alt='Юлія Михайлівна Чарівна Няня'
          />
          <div>
            <span>Юлія Михайлівна</span>
            <b>Викладач</b>
            <i>«Няня на годину»</i>
            <p>
              <b>Освіта:</b> <i>неповна вища</i>
            </p>
            <p>
              <b>Стаж роботи:</b> <i>11 років</i>
            </p>
            <i>
              Дитина порине у чарівний світ казки та гри, а у Вас є можливість
              для вільного часу.
            </i>
          </div>
        </div>

        <div className='educator'>
          <img
            src='img/educators/educator_om.jpg'
            width='165'
            height='165'
            alt='Олена Миколаївна Психолог'
          />
          <div>
            <span>Олена Миколаївна</span>
            <b>Психолог, викладач</b>
            <i>
              Підготовка до школи, Психологічна готовність до школи, Розвиток
              логіки та інтелекту, Ранній розвиток «Я і мама» (з 2-х років),
              Психологічний супровід дітей міні-саду
            </i>
            <p>
              <b>Освіта:</b> <i>вища</i>
            </p>
            <p>
              <b>Стаж роботи:</b> <i>18 років</i>
            </p>
            <i>
              Проведення психодіагностичної, корекційної та розвиткової роботи з
              дітьми та допоможе батькам розібратися: чому дитина перестала
              слухатися? Чому стала агресивною? Одні діти товариські, сміливі,
              інші – сором’язливі, нерішучі. Чому? Як справитися із шкідливими
              звичками у дитини? та ін.
            </i>
          </div>
        </div>
      </div>
    </section>

    <RandomArticle />

    <Contacts />
  </Fragment>
);

export default About;
